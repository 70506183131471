import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'patienteninformationen',
    loadChildren: () => import('./patienteninformationen/patienteninformationen.module').then( m => m.PatienteninformationenPageModule)
  },
  {
    path: 'leitlinien',
    loadChildren: () => import('./leitlinien/leitlinien.module').then( m => m.LeitlinienPageModule)
  },
  {
    path: 'awmf-regelwerk',
    loadChildren: () => import('./awmf-regelwerk/awmf-regelwerk.module').then( m => m.AwmfRegelwerkPageModule)
  },
  {
    path: 'statistik',
    loadChildren: () => import('./statistik/statistik.module').then( m => m.StatistikPageModule)
  },
  {
    path: 'suche',
    loadChildren: () => import('./suche/suche.module').then( m => m.SuchePageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./admin/update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'service',
    loadChildren: () => import('./service/service.module').then( m => m.ServicePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
