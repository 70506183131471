import { Component } from '@angular/core';
import { awmf_website_url, awmf_regelwerk_url, awmf_interessenerklaerung_url } from './global-variables';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public awmf_website_url   = awmf_website_url;
  public awmf_regelwerk_url = awmf_regelwerk_url;
  public awmf_interessenerklaerung_url = awmf_interessenerklaerung_url;

  constructor() {
  }
}
